@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"


























































.radio-control
  .control
    cursor: pointer
