@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"



















.label-control
  .required
    padding-left: 2px
    color: $warning-color
